@tailwind components;

@layer components {
  .h1 {
    font-size: 25px;
    line-height: 120%;
  }
  .h2 {
    font-size: 20px;
    line-height: 130%;
    text-transform: uppercase;
  }
  .h3 {
    font-size: 20px;
    line-height: 130%;
    text-transform: uppercase;
  }
  .h4 {
    font-size: 15px;
    line-height: 135%;
    text-transform: uppercase;
  }
  .h5 {
    font-weight: 400;
    font-size: 15px;
    line-height: 135%;
    text-transform: uppercase;
  }
  .h6 {
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    text-transform: uppercase;
  }
  .h7 {
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    text-transform: uppercase;
  }
  .h8 {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-transform: uppercase;
  }
  .number {
    font-size: 90px;
    line-height: 105px;
  }
  .number-1 {
    font-size: 100px;
    line-height: 117px;
  }
  .desc-num {
    font-size: 20px;
    line-height: 23px;
  }
  .text {
    font-size: 16px;
    line-height: 150%;
  }
  .text1 {
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
  }
  .text2 {
    font-size: 11px;
    line-height: 1.5;
  }
  .text3 {
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
  }
  .text4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
  }
  .btn {
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
  }
  .menu-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: Proxima Nova, sans-serif;
  }
  .button-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
  .input {
    padding: 0 5px;
    height: 30px;
    border: none;
    border-bottom: 1px solid;
    font-size: 16px;
    line-height: 150%;
    outline: none;
    background-color: transparent;
  }
  .input::placeholder {
    color: rgba(0,0,0, .5);
  }
  .input.white::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  .wrap {
    padding: 30px 16px 25px;
  }
  .grid-10 {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 20px;
  }
  .grid-12 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
  }
  .grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  .link {
    transition: all 0.2s;
  }
  .link:hover {
    opacity: 0.5;
  }

  @media screen(md) {
    .h1 {
      font-size: 30px;
    }
    .h2 {
      font-size: 40px;
    }
    .h3 {
      font-size: 30px;
      line-height: 145%;
    }
    .h4 {
      font-size: 25px;
      line-height: 29px;
    }
    .h5 {
      font-size: 20px;
      line-height: 23px;
    }
    .h6 {
      font-size: 15px;
      line-height: 120%;
    }
    .h7 {
      font-size: 17px;
      line-height: 140%;
    }
    .number {
      font-size: 120px;
      line-height: 140px;
    }
    .number-1 {
      font-size: 200px;
      line-height: 234px;
    }
    .desc-num {
      font-size: 30px;
      line-height: 35px;
    }
    .text {
      font-size: 18px;
    }
    .text1 {
      font-size: 14px;
    }
    .text2 {
      font-size: 14px;
    }
    .text4 {
      font-size: 14px;
    }
    .btn {
      font-size: 18px;
      line-height: 21px;
    }
    .button-text {
      font-size: 14px;
      line-height: 17px;
    }
    .wrap {
      padding: 70px 45px;
    }
    .input {
      height: 36px;
      font-size: 18px;
      line-height: 150%;
    }
  }

  @media screen(xl) {
    .h1 {
      font-size: 40px;
    }
    .h2 {
      font-size: 60px;
    }
    .h3 {
      font-size: 40px;
    }
    .h4 {
      font-size: 30px;
      line-height: 150%;
    }
    .h5 {
      font-size: 25px;
      line-height: 29px;
    }
    .h6 {
      font-weight: 600;
      font-size: 17px;
      line-height: 19px;
    }
    .h7 {
      font-size: 20px;
      line-height: 120%;
    }
    .h8 {
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
    }
    .number {
      font-size: 200px;
      line-height: 234px;
    }
    .number-1 {
      font-size: 300px;
      line-height: 351px;
    }
    .desc-num {
      font-size: 40px;
      line-height: 130%;
    }
    .text {
      font-size: 20px;
    }
    .text1 {
      font-size: 18px;
    }
    .text2 {
      font-size: 16px;
    }
    .text4 {
      font-weight: 400;
      font-size: 17px;
      line-height: 150%;
    }
    .btn {
      font-size: 20px;
      line-height: 23px;
    }
    .button-text {
      font-size: 16px;
      line-height: 2px;
    }
    .wrap {
      padding: 90px 45px 100px;
    }
    .input {
      height: 46px;
      padding: 0 10px;
      font-size: 20px;
    }
  }
  .transition {
    transition: 0.2s all;
  }
  .rotate {
    animation: rotate 20s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
  }
}
