@tailwind utilities;

@layer utilities {
  .underline {
    text-decoration-skip: none;
  }
  .dont-break-out {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
  }
}
