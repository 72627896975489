@font-face {
  font-family: 'Proxima Nova';
  src: url('../Proxima-Nova-Cyr/ProximaNova-Reg.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../Proxima-Nova-Cyr/ProximaNova-Bold.otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../Proxima-Nova-Cyr/ProximaNova-Light.otf');
  font-weight: 300;
  font-style: normal;
}
