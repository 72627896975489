@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');
@import url('/src/assets/fonts//Proxima-Nova-Cyr/stylesheet.css');

@import "tailwindcss/base";
@import "./base.css";

@import "tailwindcss/components";
@import "./components/slider.css";
@import "./components/app.css";

@import "tailwindcss/utilities"; 
@import "./utilities.css";

body {
  @apply text-black;
  font-size: 14px;
  font-family: "Tenor Sans", sans-serif;
  overflow-x: hidden;
}

.ymaps[class*="search__suggest"] {
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 155%;
  font-family: "Tenor Sans", sans-serif;
  text-transform: uppercase;
}

.ymaps[class*="suggest-item"] {
  padding: 4px 10px;
}

[class*="ymaps-2"][class*="-ground-pane"] {
  -webkit-filter: grayscale(100%) url(../assets/images/mask.svg#darkfilter);
  filter: grayscale(100%) url(../assets/images/mask.svg#darkfilter);
 }

 [class*="ymaps-2"][class*="-ground-pane"] { 
  -ms-filter: grayscale(1); 
  -webkit-filter: grayscale(1); 
  -moz-filter: grayscale(1); 
  -o-filter: grayscale(1); 
  filter: grayscale(1); 
} 
[class*="-ground-pane"] { 
  mix-blend-mode: difference; 
  opacity: 0.9;
} 

.button1 path {
  stroke-width: 2;
  stroke-dasharray: 100%, 0;
  transition: all 0.35s linear;
}

.button1:hover path {
  stroke-dasharray: 0%, 350%;
  stroke-dashoffset: -350%;
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
}


.leaflet-container {
  top: 0;
  right: 0;
  bottom: -1px;
  left: 0;
  position: absolute;
  z-index: 1;
}

.leaflet-div-icon {
  border: none !important;
  background: none !important;
}